






































import Vue, { PropType } from 'vue';
import HotelDeets from '@/types/HotelDeets';
import { safeRenderMarkdown } from '@/lib/markdown';

export default Vue.extend({
  props: {
    hotel: {
      type: Object as PropType<HotelDeets>,
      required: true,
    },
  },

  computed: {
    phoneHref(): string {
      const digits = this.hotel.phone.replace(/[^0-9]/g, '');
      const nationalized = digits.length === 10 ? `1${digits}` : digits;
      const prefixed = nationalized.length === 11 ? `+${nationalized}` : nationalized;
      return `tel:${prefixed}`;
    },

    emailHref(): string {
      return `mailto:${this.hotel.email.trim()}`;
    },

    websiteHref(): string {
      return this.hotel.website;
    },

    descriptionHtml(): string {
      return safeRenderMarkdown(this.hotel.description);
    },

    calloutHtml(): string {
      const { callout } = this.hotel;
      return callout ? safeRenderMarkdown(callout) : "";
    },

    problemHtml(): string {
      const { problem } = this.hotel;
      return problem ? safeRenderMarkdown(problem) : "";
    },
  },
});
