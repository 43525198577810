

























import { flatten, noop, sortBy, throttle } from 'underscore';
import Vue from 'vue';
import HotelDeets, { HOTELS } from '@/types/HotelDeets';
import HotelItem from '@/components/HotelItem.vue';
import MainNav from '@/components/MainNav.vue';
import { partition } from '@/lib/utils';

let syncScrolled = noop;
let flickerTimeout = 0;
let toggleTimeout = 0;

export default Vue.extend({
  components: {
    MainNav,
    HotelItem,
  },

  data() {
    return {
      sortField: 'minutesAway' as keyof HotelDeets,
      scrolled: false,
      noIsLit: false,
    };
  },

  computed: {
    sortedHotels(): HotelDeets[] {
      const sorted = sortBy(HOTELS, this.sortField);
      const promoted = partition(sorted, hotel => !!hotel.callout);
      return flatten(promoted);
    },
  },

  mounted(): void {
    this.addListeners();
    this.addIntervals();
  },

  beforeDestroy(): void {
    this.removeListeners();
    this.removeIntervals();
  },

  beforeRouteUpdate(_to, _from, next): void {
    this.addListeners();
    this.addIntervals();
    next();
  },

  beforeRouteLeave(_to, _from, next): void {
    this.removeListeners();
    this.removeIntervals();
    next();
  },

  methods: {
    removeListeners(): void {
      // document.body.removeEventListener('scroll', syncScrolled);
      window.removeEventListener('scroll', syncScrolled);
    },

    addListeners(): void {
      this.removeListeners();

      syncScrolled = throttle(() => {
        // this.scrolled = document.body.scrollTop > 100;
        this.scrolled = window.scrollY > 100;
      }, 300);

      syncScrolled();
      // document.body.addEventListener('scroll', syncScrolled);
      window.addEventListener('scroll', syncScrolled);
    },

    removeIntervals(): void {
      window.clearTimeout(flickerTimeout);
      window.clearTimeout(toggleTimeout);
    },

    addIntervals(): void {
      // With a base flicker time of 200ms, the flickers last no more than 400ms
      // due to FUCKING BASIC MATH THAT I FORGOT ABOUT. Say hi to Mr. Gleason
      // for me and please don't tell him that I forgot how to do simple limits.
      // Anyway, that means the next toggle should happen no less than 400ms
      // minimum after the last flicker.
      const toggle = (count = 0, flippityDoo = false): void => {
        this.noIsLit = !this.noIsLit;
        if (count < 6) {
          window.clearTimeout(flickerTimeout);
          flickerTimeout = window.setTimeout(() => {
            toggle(count + 1, flippityDoo);
          }, 200 / (count + 1));
        } else {
          window.clearTimeout(toggleTimeout);
          toggleTimeout = window.setTimeout(() => {
            toggle(0, !flippityDoo);
          }, flippityDoo ? 6000 : 100);
        }
      };

      toggle();
    },
  },
});
